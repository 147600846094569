/* eslint-disable class-methods-use-this */
import * as AppStateActions from '../../../modules/state/app-state.actions';

import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { calculateViewTicketDuration, calculateViewTicketZone } from '../../../../shared/utils/resolve-ticket-data';

import { AppState } from '../../../modules/state/app.state';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: `app-home`,
    templateUrl: `home.component.html`,
    styleUrls: [`./home.component.scss`],
})
export class HomeComponent {
    @Select(AppState.selectedTransportAuthority)
    selectedTransportAuthority$!: Observable<any>;

    tickets: any[] = [];
    homePageCards: string[] = [];
    homePageText = environment.instanceConfiguration.home.mainText;
    mainTextClass = environment.instanceConfiguration.home.textClass;

    selectedTransportAuthority!: any;

    transportAuthoritySubscription!: Subscription;

    carouselResponsiveOptions = [
        {
            breakpoint: `9999px`,
            numVisible: 3,
            numScroll: 3,
        },
        {
            breakpoint: `1280px`,
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: `1024px`,
            numVisible: 1,
            numScroll: 1,
        },
    ];

    constructor(private readonly router: Router, private readonly store: Store) {}

    ngOnInit() {
        // const sliderTicketNames = environment.instanceConfiguration.home.sliderTickets;

        this.homePageCards = environment.instanceConfiguration.home.cards;

        this.store.dispatch(new AppStateActions.HideCart(true));
        this.store.dispatch(new AppStateActions.ShowHeaderDropdown(true));

        this.transportAuthoritySubscription = this.selectedTransportAuthority$.subscribe(
            (selectedTransportAuthority) => {
                this.selectedTransportAuthority = selectedTransportAuthority;

                if (!this.selectedTransportAuthority) {
                    return;
                }

                const { tickets } = this.selectedTransportAuthority;

                // const sliderTickets = [];

                // const validTickets = tickets.filter((ticket: any) => sliderTicketNames.includes(ticket.name));
                //
                // const sliderTickets = [];
                //
                // for (const ticketName of sliderTicketNames) {
                //     const foundTicket = validTickets.map((ticket: any) => ticket.name).includes(ticketName);
                //
                //     if (foundTicket) {
                //         sliderTickets.push(validTickets.find((ticket: any) => ticket.name === ticketName));
                //     }
                // }

                this.tickets = tickets;
            },
        );
    }

    ngOnDestroy() {
        this.transportAuthoritySubscription.unsubscribe();
    }

    goToTicket(ticketName: string) {
        void this.router.navigate([`${this.selectedTransportAuthority.slug}/bilety/${ticketName}`]);
    }

    resolveTicketZone(ticket: any) {
        return calculateViewTicketZone(ticket);
    }

    resolveTicketDuration(ticket: any) {
        return calculateViewTicketDuration(ticket);
    }

    goToRegistration() {
        void this.router.navigateByUrl(`uzytkownik/rejestracja`);
    }

    goToTickets() {
        const route = this.router.url;
        void this.router.navigateByUrl(`${route}/bilety`);
    }

    goToCart() {
        window.scrollTo({
            top: 0,
            behavior: `smooth`,
        });
        this.store.dispatch(new AppStateActions.HideCart(false));
    }
}
/*
canActivateFrom: "2021-06-01T13:28:44.000Z"
canActivateTo: "2022-01-28T14:28:49.000Z"
changeAllowed: false
config: {}
createdAt: "2021-06-07T13:31:06.472Z"
customDurationRange: null
customDurationRangeId: null
displayedFrom: "2021-06-07T13:28:53.000Z"
displayedTo: "2021-11-20T14:28:56.000Z"
duration: "20 minutes strict"
id: "05f08869-59e4-4ee0-85d0-0efe03ee8670"
inSaleFrom: "2021-06-07T13:28:58.000Z"
inSaleTo: "2021-10-30T13:29:00.000Z"
isBearer: false
metadata: {}
name: "20 minutowy"
priceWithTax: 120
priceWithoutTax: 150
referenceNumber: 1
tags: (3) [{…}, {…}, {…}]
transportAuthorityId: "2595c8d5-735a-47ca-8e69-2a7737555805"
transportLines: (2) [{…}, {…}]
transportZones: [{…}]
updatedAt: "2021-06-08T08:46:23.631Z"
validFrom: "2021-06-01T13:29:15.000Z"
validTo: "2021-12-24T14:29:18.000Z"
*/
