import { CarouselModule } from 'primeng/carousel';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home.component';
import { LayoutModule } from '../../../../shared/components/layout';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const primeng = [CarouselModule, DropdownModule];

@NgModule({
    imports: [
        ...primeng,
        LayoutModule,
        FormsModule,
        CommonModule,
        RouterModule.forChild([
            {
                path: ``,
                component: HomeComponent,
            },
        ]),
        TranslateModule,
    ],
    declarations: [HomeComponent],
})
export class HomeModule {}
