<section class="container mx-auto grid px-10 md:pb-0 pb-4 pt-10 items-center intro">
    <h1 [ngClass]="mainTextClass">
        {{ homePageText | translate }}
    </h1>
    <img width="864" height="332" src="/assets/local/autobus.png" />
</section>

<section class="container mx-auto h-48 md:h-16">
    <div
        class="search-ticket grid gap-4 justify-center md:grid-flow-col md:justify-evenly mx-auto p-6 mt-6 w-11/12 lg:w-3/5 rounded-lg"
    >
        <div class="flex items-center text-theme search-ticket-text">
            <span class="svg text-4xl svg-bus-ticket mr-4"></span>
            {{ 'home.callToActionTitle' | translate }}
        </div>

        <button class="theme-button" [routerLink]="['bilety']">
            {{ 'home.callToAction' | translate }}
        </button>
    </div>
</section>

<section class="container mx-auto mt-8 relative">
    <p-carousel [value]="tickets" [numVisible]="4" [numScroll]="1" [responsiveOptions]="carouselResponsiveOptions">
        <ng-template let-ticket pTemplate="item">
            <div
                class="grid-container bus-ticket mx-auto carousel-ticket bg-cover cursor-pointer m-2"
                (click)="goToTicket(ticket.id)"
                title="{{ ticket.name }}"
            >
                <div class="icon"></div>
                <div class="content">
                    <div class="content-name">{{ ticket.name }}</div>
                    <div class="content-body">
                        <div class="content-item">
                            {{ 'ticket.duration' | translate }}: {{ resolveTicketDuration(ticket) }}
                        </div>
                        <div class="content-item">
                            {{ 'ticket.zone' | translate }}: {{ resolveTicketZone(ticket) }}
                        </div>
                        <div class="content-item">
                            {{ 'ticket.price' | translate }}: {{ ticket.priceWithTax / 100 | currency: 'PLN' }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</section>

<section class="container mx-auto mb-10">
    <h2 class="text-theme py-8 section-header">
        {{ 'home.howItWorks' | translate }}
    </h2>
    <ol class="px-4 grid grid-rows-3 gap-12 lg:grid-rows-none lg:grid-cols-3 xl:gap-32">
        <li class="flex flex-col justify-center cursor-pointer" (click)="goToRegistration()">
            <div class="flex flex-grow flex-col shadow-theme home-card home-card-odd">
                <div [innerHtml]="homePageCards[0]"></div>
                <h3 class="text-2xl home-card-header home-card-odd-header">
                    {{ 'home.steps.0.title' | translate }}
                </h3>
                <div class="text-justify home-card-content home-card-odd-content">
                    {{ 'home.steps.0.description' | translate }}
                </div>
            </div>
        </li>
        <li class="flex flex-col justify-center cursor-pointer" (click)="goToTickets()">
            <div class="flex flex-grow flex-col shadow-theme home-card home-card-even">
                <div [innerHtml]="homePageCards[1]"></div>
                <h3 class="text-2xl home-card-header home-card-even-header">
                    {{ 'home.steps.1.title' | translate }}
                </h3>
                <div class="text-justify home-card-content home-card-even-content">
                    {{ 'home.steps.1.description' | translate }}
                </div>
            </div>
        </li>
        <li class="flex flex-col justify-center cursor-pointer" (click)="goToCart()">
            <div class="flex flex-grow flex-col shadow-theme home-card home-card-odd">
                <div [innerHtml]="homePageCards[2]"></div>
                <h3 class="text-2xl home-card-header home-card-odd-header">
                    {{ 'home.steps.2.title' | translate }}
                </h3>
                <div class="text-justify home-card-content home-card-odd-content">
                    {{ 'home.steps.2.description' | translate }}
                </div>
            </div>
        </li>
    </ol>
</section>
